<script>
import { mapActions, mapGetters } from 'vuex'
import { accountMixin } from '@common/mixins/account/accountMixin'
import { EventBus } from '@common/lib/event-bus'
import usePermissions from '@common/composables/usePermissions'
import TopNotificationDropdown from '@src/components/common/TopNotificationDropdown.vue'
import { useComposerHelper } from '@modules/composer_v2/composables/useComposerHelper'
import { swalAttributes } from '@common/constants/common-attributes'
import usePlatform from '@common/composables/usePlatform'
import useWhiteLabelApplication from '@modules/setting/composables/whitelabel/useWhiteLabelApplication'
import useSocialAccountModal from '@/src/modules/account/composables/useSocialAccountsModal'
import {useFeatures} from "@modules/billing/composables/useFeatures";
import {useBilling} from "@modules/billing/composables/useBilling";

export default {
  components: {
    TopNotificationDropdown,
  },
  mixins: [accountMixin],
  setup() {
    const { appData, shouldShowWhiteLabelData } = useWhiteLabelApplication()
    const { hasPermission, getUserRole } = usePermissions()
    const { isDirty, buttonText } = useSocialAccountModal()
    const { isDraftComposer, draftPlanId } = useComposerHelper()
    const { topBannersHeight } = usePlatform()
    const {showUpgradeModal} = useBilling()
    const {canAccess} = useFeatures()
    const inboxAccess = canAccess('social_inbox')
    const blogPostAccess = canAccess('blog_publishing')
    const contentFeedAccess = canAccess('content_discovery')
    const influencersAccess = canAccess('influencer_discovery')
    const whiteLabelAddonAccess = canAccess('white_label_addon')

    return {
      isDirty,
      buttonText,
      topBannersHeight,
      isDraftComposer,
      draftPlanId,
      getUserRole,
      appData,
      shouldShowWhiteLabelData,
      inboxAccess,
      blogPostAccess,
      contentFeedAccess,
      influencersAccess,
      whiteLabelAddonAccess,
      hasPermission,
      showUpgradeModal,
    }
  },
  data() {
    return {
      notificationTabStatus: 'head_left',
      analyticsView: 'off',
      socialInboxView: 'off',
      DiscoverHover: false,
      PublishHover: false,
      toggleNotificationDropdown: false,
      isSmallScreen: false,
      moreHover: false,
      isWorkspaceDropdownOpen: false,
    }
  },
  computed: {
    ...mapGetters([
      'getActiveWorkspaceAddonsLifetime',
      'getProfile',
      'getWorkspaces',
      'getTotalWorkspaceNotificationsCount',
      'getSystemWorkspaceNotificationsCount',
      'getTeamWorkspaceNotificationsCount',
      'getInboxWorkspaceNotificationsCount',
      'getPlan',
      'isAnalyticsPlanAllowed',
      'getWorkspaceNotifications',
    ]),
    isPublishRoute() {
      return this.$route.path.includes('/automation')
    },
    isDiscoverRoute() {
      return this.$route.path.includes('/discovery/')
    },
    isAnalyticsEnabledForUser() {
      if (this.isAnalyticsPlanAllowed) {
        return true
      }
      return this.getActiveWorkspaceAddonsLifetime.analytics
    },
    animateDiscovery() {
      switch (this.$route.name) {
        case 'search_web':
          return true
      }
      return false
    },
    isAnalyzeRoute() {
      return this.$route.path.includes('/analytics/')
    },
    defaultPlannerRouteName() {
      switch (this.getProfile.planner_default_view) {
        case 'calendar':
          return 'planner_calendar_v2'
        case 'feed':
          return 'planner_feed_v2'
        case 'compact_list':
          return 'planner_list_compact_v2'
        case 'grid_instagram':
          return 'planner_instagram_grid_view'
        case 'grid_tiktok':
          return 'planner_tiktok_grid_view'
        default:
          return 'planner_list_v2'
      }
    },
  },
  mounted() {
    this.topBannersHeight =
      this.topBannersHeight + this.$refs.topHeaderBar.clientHeight
    this.checkSmallView()
    window.addEventListener('resize', this.checkSmallView)

    EventBus.$on('notification-dropdown-close', () => {
      this.notificationDropdownClose?.()
    })
    window.onload = function () {
      const myiFrame = document.getElementById('HW_frame')
      if (myiFrame) {
        const doc = myiFrame.contentDocument
        if (doc?.body) {
          doc.body.innerHTML =
            doc.body.innerHTML +
            '<style>/******* Put your styles here ******* .body{font-size: 12px}</style>'
        }
      }
    }
    document.addEventListener('click', this.handleClickOutside, true)
  },
  created() {
    // add false check for old users not having email_verify key
    // we don't have to show email verification page for them
    if (this.getProfile?.email_verify === false) {
      this.$router.push({ name: 'email_verification' })
    }
    console.log(this.$route)
    if (
      this.getActiveWorkspaceAddonsLifetime.social_inbox_beta ||
      this.getActiveWorkspaceAddonsLifetime.social_inbox
    ) {
      this.socialInboxView = 'on'
    } else {
      this.socialInboxView = 'off'
    }
    // if the user is from the monthly/trial plan, enable the social inbox and analytics add-on for them.

    if (this.isAnalyticsPlanAllowed) {
      this.analyticsView = 'on'
      this.socialInboxView = 'on'
      console.log('Analytics plan allowed > true')
      return true
    }
    console.log('Analytics plan allowed > false')
    if (this.getActiveWorkspaceAddonsLifetime.analytics) {
      this.analyticsView = 'on'
    }
    if (this.getActiveWorkspaceAddonsLifetime.social_inbox) {
      this.socialInboxView = 'on'
    }
  },
  beforeUnmount() {
    this.topBannersHeight -= this.$refs.topHeaderBar.clientHeight
    window.removeEventListener('resize', this.checkSmallView)
    EventBus.$off('notification-dropdown-close', () => {
      this.notificationDropdownClose?.()
    })
    document.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    ...mapActions(['setUtmsStatus', 'setUtms', 'markAllNotificationsRead']),
    async handleLogout() {
      let res = false
      if (this.isDraftComposer) {
        const options = {
          ...swalAttributes(),
          modalClass:
            'sweet-alert-confirmation fade sweet-alert-minimize-composer',
          okVariant: 'primary',
          hideHeader: false,
        }
        res = await this.$bvModal.msgBoxConfirm(
          'You have a post currently in the composer that is minimized. What would you like to do?',
          {
            title: '⚠️ Unfinished Post is in the Composer!',
            ...options,
            cancelTitle: 'Save & Logout',
            okTitle: 'Return to Composer',
          }
        )
      }

      if (res === null) {
        return
      }

      if (!res) {
        localStorage.removeItem('composerWidgetPosition')
        this.isDraftComposer = false
        this.draftPlanId = ''
        this.logout()
      } else {
        const workspace = this.$route.params.workspace
        history.pushState(
          {},
          null,
          `/${workspace}/composer/${this.draftPlanId}`
        )
        this.$bvModal.show('composer-modal')
      }
    },
    async handleWorkspaceChange(item) {
      let res = false
      if (this.isDraftComposer) {
        const options = {
          ...swalAttributes(),
          modalClass:
            'sweet-alert-confirmation fade sweet-alert-minimize-composer',
          okVariant: 'primary',
          hideHeader: false,
        }
        res = await this.$bvModal.msgBoxConfirm(
          'You have a post currently in the composer that is minimized. What would you like to do?',
          {
            title: '⚠️ Unfinished Post is in the Composer!',
            ...options,
            cancelTitle: 'Save & Switch Workspace',
            okTitle: 'Return to Composer',
          }
        )
      }

      if (res === null) {
        return
      }

      if (!res) {
        localStorage.removeItem('composerWidgetPosition')
        this.isDraftComposer = false
        this.draftPlanId = ''

        this.isDirty = false
        this.buttonText = `Skip & Connect`
        this.shouldChangeWorskspace(item)
      } else {
        const workspace = this.$route.params.workspace
        history.pushState(
          {},
          null,
          `/${workspace}/composer/${this.draftPlanId}`
        )
        this.$bvModal.show('composer-modal')
      }
    },
    getSubscriptionLimits(key) {
      if (
        this.getPlan &&
        this.getPlan.subscription &&
        this.getPlan.subscription.limits &&
        this.getPlan.subscription.limits[key]
      ) {
        return this.getPlan.subscription.limits[key] ?? 0
      }
      return 0
    },
    async dispatchEvent(ev) {
      // Create the event.
      EventBus.$emit('close-widget')
      this.toggleWorkspaceDropdown()
    },
    toggleWorkspaceDropdown() {
      this.isWorkspaceDropdownOpen = !this.isWorkspaceDropdownOpen
    },
    handleClickOutside(event) {
      if (
        this.$refs.workspaceDropdown &&
        !this.$refs.workspaceDropdown.contains(event.target)
      ) {
        this.isWorkspaceDropdownOpen = false
      }
    },
    showLatestChanges() {
      console.debug('Method:showLatestChanges')
      // eslint-disable-next-line no-undef
      $('#HW_frame_cont').toggleClass('HW_visible')
    },
    notificationDropdown() {
      this.toggleNotificationDropdown = !this.toggleNotificationDropdown
    },
    notificationDropdownClose() {
      this.toggleNotificationDropdown = false
    },
    hideLatestChanges() {
      console.debug('Method:hideLatestChanges')
      if (
        this.getWorkspaceNotifications.system_list.data &&
        this.getWorkspaceNotifications.system_list.data.length === 0
      ) {
        this.notificationTabStatus =
          this.getActiveWorkspaceAddonsLifetime.social_inbox_beta ||
          this.getActiveWorkspaceAddonsLifetime.social_inbox ||
          this.isAnalyticsPlanAllowed
            ? 'head_center'
            : 'head_right'
      }
      // eslint-disable-next-line no-undef
      $('#HW_frame_cont').removeClass('HW_visible')
    },
    closeDropdownHeadway() {
      // eslint-disable-next-line no-undef
      $('#HW_frame_cont').removeClass('HW_visible')
    },
    profileRouter() {
      // eslint-disable-next-line no-undef
      $('#profile_dropdown_id .dropdown_header').dropdown('toggle')
    },
    notificationTab(type) {
      this.notificationTabStatus = type
    },
    navigateToDiscovery() {
      // this.initializeTopics()
      // this.initializeSearch()
      this.viewDisplayTopics()
    },
    async createPost(postType) {
      this.PublishHover = false
      await this.changePublishType(
        postType,
        this.isDraftComposer,
        this.draftPlanId
      )
    },
    checkSmallView() {
      this.isSmallScreen = window.innerWidth < 1048
    },
  },
}
</script>

<template>
  <div
    ref="topHeaderBar"
    class="top-navbar top_header_component d-flex align-items-center"
    :class="{
      past_due_header:
        getProfile.state === 'past_due' &&
        !(
          $parent.getInstagramAccountsWithPermissionsIssue().no_access_accounts
            .length > 0 &&
          isTrialPlan() === false &&
          $parent.showTopHeaderBar
        ),
    }"
  >
    <div class="component_inner">
      <div class="d-flex align-items-center">
        <div class="left_nav">
          <ul>
            <li class="nav_li">
              <router-link
                :to="{ name: 'workspaces' }"
                data-cy="workspaces_home"
              >
                <img
                  :src="
                    (shouldShowWhiteLabelData && appData.brandLogo) ||
                    require(`@src/assets/img/logo/nav-logo.png`)
                  "
                  :alt="appData.businessName"
                  class="h-9 object-contain max-w-[2.25rem] xl:max-w-[5rem]"
                />
              </router-link>
            </li>
            <li v-if="getWorkspaces.activeWorkspace._id" class="nav_li">
              <div
                ref="workspaceDropdown"
                class="workspace_dropdown dropdown default_style_dropdown"
              >
                <div
                  class="dropdown_header d-flex align-items-center"
                  @click="dispatchEvent"
                >
                  <div class="w_img">
                    <img
                      :src="getWorkspaces.activeWorkspace.logo"
                      alt=""
                      @error="
                        $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/no_media_found.svg`
                      "
                    />
                  </div>
                  <div class="flex items-center">
                    <span>
                      <span class="title">workspace</span>
                      <span class="name" data-cy="workspace_name">{{
                        getWorkspaces.activeWorkspace.name
                      }}</span>
                    </span>
                  </div>
                  <clip-loader
                    v-if="isWorkspaceLoading"
                    color="#ffffff"
                    size="16px"
                  ></clip-loader>
                  <span v-else class="ml-auto">
                    <i
                      v-if="
                        hasPermission('can_access_top_header') ||
                        getWorkspaces.items.length > 1
                      "
                      class="icon_last cs-angle-down"
                    ></i>
                  </span>
                </div>

                <div
                  v-if="
                    hasPermission('can_access_top_header') ||
                    getWorkspaces.items.length > 1
                  "
                  x-placement="bottom-end"
                  class="dropdown-menu dropdown-menu-center"
                  :class="{ show: isWorkspaceDropdownOpen }"
                >
                  <div class="p-2">
                    <div class="search-bar-input">
                      <div class="search-bar-inner">
                        <input
                          v-model="getWorkspaces.search"
                          type="text"
                          placeholder="Search a workspace…"
                          class="input-sm"
                        />
                        <button class="search_btn">
                          <i class="icon-Search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <ul class="inner">
                    <template v-if="filteredWorkspaces.length">
                      <template v-for="item in filteredWorkspaces">
                        <li
                          v-if="
                            item.status === 'joined' &&
                            checkIfWorkspaceHasPaymentIssue(item)
                          "
                          :key="item.workspace_id"
                          v-tooltip.bottom="getWorkspaceLockMessage(item)"
                          class="list_item"
                          :class="{
                            active:
                              item.workspace._id ===
                              getWorkspaces.activeWorkspace._id,
                          }"
                        >
                          <a class="d-flex align-items-center">
                            <div class="w_img">
                              <img
                                :src="item.workspace.logo"
                                alt=""
                                @error="
                                  $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/no_media_found.svg`
                                "
                              />
                            </div>
                            <span class="name">{{ item.workspace.name }}</span>
                            <i class="fas fa-lock ml-auto"></i>
                          </a>
                        </li>
                        <li
                          v-else-if="item.status === 'joined'"
                          :key="`joined-${item.workspace_id}`"
                          class="list_item"
                          :class="{
                            active:
                              item.workspace._id ===
                              getWorkspaces.activeWorkspace._id,
                          }"
                          @click.prevent="handleWorkspaceChange(item)"
                        >
                          <a class="d-flex align-items-center">
                            <div class="w_img">
                              <img
                                :src="item.workspace.logo"
                                alt=""
                                @error="
                                  $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/no_media_found.svg`
                                "
                              />
                            </div>
                            <span class="name">{{ item.workspace.name }}</span>
                            <i class="cs-checked checked_icon"></i>
                          </a>
                        </li>
                      </template>
                    </template>
                    <p v-else-if="getWorkspaces.search" class="not_found"
                      >No workspace found for your search query.</p
                    >
                    <p v-else class="not_found">No workspace found.</p>
                  </ul>
                  <div class="footer">
                    <a
                      v-if="hasPermission('can_save_workspace')"
                      href=""
                      class="add_icon"
                      data-cy="create-workspace"
                      @click.prevent="createWorkspaceModal"
                    >
                      <i class="cs-plus"></i>
                      <span class="icon_text">Add New</span>
                    </a>

                    <router-link class="add_icon" :to="{ name: 'workspaces' }">
                      <i class="icon-Listview_icon"></i>
                      <span class="icon_text" data-cy="view-workspace"
                        >View All</span
                      >
                    </router-link>
                  </div>
                </div>
              </div>
            </li>

            <li
              v-if="hasPermission('can_access_top_header')"
              v-click-away="closeDropdownHeadway"
              class="nav_li"
            >
              <a
                v-tooltip.top="'Change Log'"
                href="javascript:void(0);"
                class="change_logs bell_icon_light right-clone"
                @click="showLatestChanges"
              >
                <i class="d-block icon-flag-cs"></i>
              </a>
            </li>
          </ul>
        </div>
        <div class="right_nav d-flex align-items-center">
          <ul class="links">
            <li v-if="!hasPermission('can_access_top_header')">
              <div
                class="link-a"
                data-cy="header_publish"
                @click="$router.push({ name: 'planner_v2' })"
              >
                View Content
              </div>
            </li>
            <!-- checking approver member case, as they only got the access for Planner and Analytics -->
            <li v-if="hasPermission('can_access_top_header')">
              <router-link
                :to="{ name: 'dashboard' }"
                class="text-gray-100 hover:text-gray-200 flex flex-col items-center text-sm rounded-lg p-1.5 px-2.5"
              >
                <img
                  src="@src/assets/img/top_navigation/icons/dashboard.svg"
                  class="w-5 h-5 mb-1.5"
                />
                <span>Dashboard</span>
              </router-link>
            </li>
            <li
              v-if="hasPermission('can_access_top_header')"
              @mouseover="PublishHover = true"
              @mouseleave="PublishHover = false"
            >
              <div
                class="link-a text-gray-100 hover:text-gray-200 flex flex-col items-center text-sm rounded-lg p-1.5 px-2.5"
                data-cy="header_publish"
                :class="{ active: isPublishRoute }"
              >
                <img
                  src="@src/assets/img/top_navigation/icons/compose.svg"
                  class="w-5 h-5 mb-1.5"
                />
                <span>Compose <i class="ml-2 far fa-angle-down"></i></span>
              </div>
              <transition name="slide-bottom">
                <div v-if="PublishHover" class="link-dropdown">
                  <div class="d-item" @click="createPost('Composer Social')">
                    <a>
                      <div class="profile_picture d-flex align-items-start">
                        <div class="picture_block">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            class="stroke"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.12132 14.1217C9.54088 13.7021 9.8266 13.1676 9.94236 12.5856C10.0581 12.0037 9.9987 11.4005 9.77164 10.8523C9.54458 10.3041 9.16006 9.83557 8.66671 9.50593C8.17336 9.17629 7.59334 9.00034 7 9.00034C6.20435 9.00034 5.44129 9.31641 4.87868 9.87902C4.31607 10.4416 4 11.2047 4 12.0003C4 12.5937 4.17595 13.1737 4.50559 13.6671C4.83524 14.1604 5.30377 14.5449 5.85195 14.772C6.40013 14.999 7.00333 15.0585 7.58527 14.9427C8.16721 14.8269 8.70176 14.5412 9.12132 14.1217ZM9.12132 14.1217L12 17M14.3787 6.5637C13.8161 6.0011 13.5 5.23803 13.5 4.44238C13.5 3.84904 13.6759 3.26902 14.0056 2.77567C14.3352 2.28233 14.8038 1.89781 15.3519 1.67075C15.9001 1.44368 16.5033 1.38427 17.0853 1.50003C17.6672 1.61578 18.2018 1.90151 18.6213 2.32106C19.0409 2.74062 19.3266 3.27517 19.4424 3.85711C19.5581 4.43906 19.4987 5.04226 19.2716 5.59043C19.0446 6.13861 18.6601 6.60715 18.1667 6.93679C17.6734 7.26644 17.0933 7.44238 16.5 7.44238C15.7044 7.44238 14.9413 7.12631 14.3787 6.5637ZM14.3787 6.5637L9.68 10.6603"
                            />
                            <path
                              d="M11.5899 21.4445C11.2053 20.8689 11 20.1922 11 19.5C11 18.5717 11.3687 17.6815 12.0251 17.0251C12.6815 16.3687 13.5717 16 14.5 16C15.1922 16 15.8689 16.2053 16.4445 16.5899C17.0201 16.9744 17.4687 17.5211 17.7336 18.1606C17.9985 18.8001 18.0678 19.5039 17.9327 20.1828C17.7977 20.8618 17.4644 21.4854 16.9749 21.9749C16.4854 22.4644 15.8617 22.7977 15.1828 22.9327C14.5039 23.0678 13.8001 22.9985 13.1606 22.7336C12.5211 22.4687 11.9744 22.0201 11.5899 21.4445Z"
                            />
                          </svg>
                        </div>
                        <div class="text_block blog_link">
                          <p class="text">Social Post</p>
                          <p class="sub_text"
                            >Create, collaborate and schedule social media
                            posts.</p
                          >
                        </div>
                      </div>
                    </a>
                  </div>

                  <div
                      v-tooltip="!blogPostAccess.allowed ? blogPostAccess.error?.message : ''"
                      class="d-item"
                      :class="{ 'disabled-item': !blogPostAccess.allowed }"
                  >
                    <a
                        v-if="!blogPostAccess.allowed"
                        href="javascript:;"
                        class="item"
                        @click.prevent="showUpgradeModal"
                    >
                      <div class="profile_picture d-flex align-items-start">
                        <div class="picture_block">
                          <i class="fas fa-lock"></i>
                        </div>
                        <div class="text_block blog_link">
                          <p class="text">Blog Post</p>
                          <p class="sub_text">
                            Compose blog posts and distribute to multiple destinations.
                          </p>
                        </div>
                      </div>
                    </a>
                    <a
                        v-else
                        @click="createPost('Composer Article')"
                    >
                      <div class="profile_picture d-flex align-items-start">
                        <div class="picture_block">
                          <svg
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              class="stroke"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                                d="M8 20H4C3.73478 20 3.48043 19.8946 3.29289 19.7071C3.10536 19.5196 3 19.2652 3 19V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3H12.59C12.8523 3.0011 13.1037 3.10526 13.29 3.29L16.71 6.71C16.8947 6.89626 16.9989 7.14766 17 7.41V9"
                            />
                            <path
                                d="M20.7102 15.3395C20.8965 15.1522 21.001 14.8987 21.001 14.6345C21.001 14.3704 20.8965 14.1169 20.7102 13.9295L19.2902 12.5095C19.1029 12.3233 18.8494 12.2188 18.5852 12.2188C18.321 12.2188 18.0676 12.3233 17.8802 12.5095L12.2202 18.1695V20.9995H15.0502L20.7102 15.3395Z"
                            />
                          </svg>
                        </div>
                        <div class="text_block blog_link">
                          <p class="text">Blog Post</p>
                          <p class="sub_text">
                            Compose blog posts and distribute to multiple destinations.
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>

                  <div class="d-item" @click="PublishHover = false">
                    <router-link
                        data-cy="header-automation"
                        :to="{
                          name: 'automation',
                          params: {
                            workspace: getWorkspaces.activeWorkspace.slug,
                          },
                        }"
                    >
                      <div class="profile_picture d-flex align-items-start">
                        <div class="picture_block">
                          <i class="far fa-robot"></i>
                        </div>
                        <div class="text_block blog_link">
                          <p class="text">Automation</p>
                          <p class="sub_text"
                          >Auto-fill your channels with 5 unique content
                            re-purposing recipes.</p
                          >
                        </div>
                      </div>
                    </router-link>
                  </div>
                </div>
              </transition>
            </li>
            <li v-if="hasPermission('can_access_top_header')">
              <router-link
                class="text-gray-100 hover:text-gray-200 flex flex-col items-center text-sm rounded-lg p-1.5 px-2.5"
                data-cy="header-analyze"
                :to="{
                  name: defaultPlannerRouteName,
                  params: { workspace: getWorkspaces.activeWorkspace.slug },
                }"
              >
                <img
                  src="@src/assets/img/top_navigation/icons/planner.svg"
                  class="w-5 h-5 mb-1.5"
                />
                <span>Planner</span>
              </router-link>
            </li>
            <li v-if="hasPermission('can_access_top_header')">
              <router-link
                class="text-gray-100 hover:text-gray-200 flex flex-col items-center text-sm rounded-lg p-1.5 px-2.5"
                data-cy="header-analyze"
                :to="{
                  name: 'analytics_v3',
                  params: { workspace: getWorkspaces.activeWorkspace.slug },
                }"
                :class="{ 'router-link-exact-active active': isAnalyzeRoute }"
              >
                <img
                  src="@src/assets/img/top_navigation/icons/analyze.svg"
                  class="w-5 h-5 mb-1.5"
                />
                <span>Analyze</span>
              </router-link>
            </li>
            <template v-if="!isSmallScreen">
              <li
                v-if="hasPermission('can_access_top_header')"
                data-cy="header-inbox"
              >
                <template v-if="!inboxAccess?.allowed">
                  <template v-if="getPlan?.subscription?.is_lifetime">
                    <router-link
                        class="text-gray-100 hover:text-gray-200 flex flex-col items-center text-sm rounded-lg p-1.5 px-2.5"
                                      :to="{
                          name: 'upgrade_lifetime_addon',
                          params: { workspace: getWorkspaces.activeWorkspace.slug },
                        }"
                    >
                      <img
                          src="@src/assets/img/top_navigation/icons/inbox.svg"
                          class="w-5 h-5 mb-1.5"
                       alt=""/>
                      <span>Inbox <i class="fas fa-lock ml-2" style="font-size: 0.875rem"></i></span>
                    </router-link>
                  </template>
                  <template v-else>
                    <a
                        v-tooltip.bottom="inboxAccess?.error?.message"
                        href="javascript:;"
                        class="text-gray-100 hover:text-gray-200 flex flex-col items-center text-sm rounded-lg p-1.5 px-2.5"
                        @click.prevent="showUpgradeModal"
                    >
                      <img
                          src="@src/assets/img/top_navigation/icons/inbox.svg"
                          class="w-5 h-5 mb-1.5"
                      />
                      <span>Inbox <i class="fas fa-lock ml-2" style="font-size: 0.875rem"></i></span>
                    </a>
                  </template>
                </template>
                <template v-else>
                  <router-link
                      :to="{ name: 'inbox' }"
                      class="text-gray-100 hover:text-gray-200 flex flex-col items-center text-sm rounded-lg p-1.5 px-2.5"
                  >
                    <img
                        src="@src/assets/img/top_navigation/icons/inbox.svg"
                        alt=""
                        class="w-5 h-5 mb-1.5"
                    />
                    <span>Inbox</span>
                  </router-link>
                </template>
              </li>

              <li
                v-if="hasPermission('can_access_top_header')"
                @mouseover="DiscoverHover = true"
                @mouseleave="DiscoverHover = false"
              >
                <div
                  class="link-a text-gray-100 hover:text-gray-200 flex flex-col items-center text-sm rounded-lg p-1.5 px-2.5"
                  data-cy="header_discover"
                  :class="{ active: isDiscoverRoute }"
                >
                  <img
                    src="@src/assets/img/top_navigation/icons/discover.svg"
                    class="w-5 h-5 mb-1.5"
                  />
                  <span> Discover<i class="ml-2 far fa-angle-down"></i> </span>
                </div>

                <transition name="slide-bottom">
                  <div v-if="DiscoverHover" class="link-dropdown">
                    <div
                        v-tooltip="!contentFeedAccess.allowed ? contentFeedAccess.error?.message : ''"
                        class="d-item"
                        :class="{ 'disabled-item': !contentFeedAccess.allowed }"
                    >
                      <a
                          v-if="!contentFeedAccess.allowed"
                          href="javascript:;"
                          class="item"
                          @click.prevent="showUpgradeModal"
                      >
                        <div class="profile_picture d-flex align-items-start">
                          <div class="picture_block">
                            <i class="fas fa-lock"></i>
                          </div>
                          <div class="text_block blog_link">
                            <p class="text">Content Feed</p>
                            <p class="sub_text">
                              Research the top perform content across your niche and share it with your audience.
                            </p>
                          </div>
                        </div>
                      </a>
                      <router-link
                          v-else
                          data-cy="header_content"
                          :to="{
                            name: 'discover_topics',
                            params: { workspace: getWorkspaces.activeWorkspace.slug },
                          }"
                          @click.native="DiscoverHover = false"
                      >
                        <div class="profile_picture d-flex align-items-start">
                          <div class="picture_block">
                            <i class="far fa-window-restore"></i>
                          </div>
                          <div class="text_block blog_link">
                            <p class="text">Content Feed</p>
                            <p class="sub_text">
                              Research the top perform content across your niche and share it with your audience.
                            </p>
                          </div>
                        </div>
                      </router-link>
                    </div>

                    <div
                        v-tooltip="!influencersAccess.allowed ? influencersAccess.error?.message : ''"
                        class="d-item"
                        :class="{ 'disabled-item': !influencersAccess.allowed }"
                    >
                      <a
                          v-if="!influencersAccess.allowed"
                          href="javascript:;"
                          class="item"
                          @click.prevent="showUpgradeModal"
                      >
                        <div class="profile_picture d-flex align-items-start">
                          <div class="picture_block">
                            <i class="fas fa-lock"></i>
                          </div>
                          <div class="text_block blog_link">
                            <p class="text">Influencers</p>
                            <p class="sub_text">
                              Identify the key influencers and pioneers in your industry from social media channels.
                            </p>
                          </div>
                        </div>
                      </a>
                      <router-link
                          v-else
                          data-cy="header_influencers"
                          :to="{
                            name: 'instagram_influencers',
                            params: { workspace: getWorkspaces.activeWorkspace.slug },
                          }"
                          @click.native="DiscoverHover = false"
                      >
                        <div class="profile_picture d-flex align-items-start">
                          <div class="picture_block">
                            <i class="far fa-users"></i>
                          </div>
                          <div class="text_block blog_link">
                            <p class="text">Influencers</p>
                            <p class="sub_text">
                              Identify the key influencers and pioneers in your industry from social media channels.
                            </p>
                          </div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </transition>
              </li>
              <li v-if="hasPermission('can_access_top_header')">
                <router-link
                  :to="{ name: 'media-library' }"
                  class="text-gray-100 hover:text-gray-200 flex flex-col items-center text-sm rounded-lg p-1.5 px-2.5"
                >
                  <img
                    src="@src/assets/img/top_navigation/icons/media-library.svg"
                    class="w-5 h-5 mb-1.5"
                  />
                  <span>Media Library</span>
                </router-link>
              </li>
            </template>
            <template v-else>
              <!-- More dropdown menu -->
              <li
                v-if="hasPermission('can_access_top_header')"
                @mouseover="moreHover = true"
                @mouseleave="moreHover = false"
              >
                <div
                  class="link-a text-gray-100 hover:text-gray-200 flex flex-col items-center text-sm rounded-lg p-1.5 px-2.5"
                >
                  <i class="fas fa-ellipsis-h text-lg"></i>
                  <span>More</span>
                </div>
                <transition name="slide-bottom">
                  <div
                    v-if="moreHover"
                    class="link-dropdown !min-w-[188px] !right-0 !left-auto"
                  >
                    <!-- Inbox                   -->
                    <div
                      v-if="hasPermission('can_access_top_header')"
                      class="d-item"
                      data-cy="header-inbox"
                    >
                      <template v-if="!inboxAccess?.allowed">
                        <template v-if="getPlan?.subscription?.is_lifetime">
                          <router-link
                              :to="{
                                name: 'upgrade_lifetime_addon',
                                params: { workspace: getWorkspaces.activeWorkspace.slug },
                              }"
                              class="profile_picture d-flex align-items-center"
                              @click="moreHover = false"
                          >
                            <div class="picture_block pt-1">
                              <i class="fas fa-inbox"></i>
                            </div>
                            <div class="text_block blog_link !cursor-pointer">
                              <p class="text-md font-semibold text-gray-900 pt-1.5">
                                Inbox <i class="fas fa-lock ml-2" style="font-size: 0.875rem"></i>
                              </p>
                            </div>
                          </router-link>
                        </template>
                        <template v-else>
                          <a
                              v-tooltip.bottom="inboxAccess?.error?.message"
                              href="javascript:;"
                              class="profile_picture d-flex align-items-center"
                              @click.prevent="showUpgradeModal"
                          >
                            <div class="picture_block pt-1">
                              <i class="fas fa-inbox"></i>
                            </div>
                            <div class="text_block blog_link !cursor-pointer">
                              <p class="text-md font-semibold text-gray-900 pt-1.5">
                                Inbox <i class="fas fa-lock ml-2" style="font-size: 0.875rem"></i>
                              </p>
                            </div>
                          </a>
                        </template>
                      </template>
                      <template v-else>
                        <router-link
                            :to="{ name: 'inbox' }"
                            class="profile_picture d-flex align-items-center"
                            @click="moreHover = false"
                        >
                          <div class="picture_block pt-1">
                            <i class="fas fa-inbox"></i>
                          </div>
                          <div class="text_block blog_link !cursor-pointer">
                            <p class="text-md font-semibold text-gray-900 pt-1.5">Inbox</p>
                          </div>
                        </router-link>
                      </template>
                    </div>
                    <!--  Media Library  -->
                    <div
                      v-if="hasPermission('can_access_top_header')"
                      class="d-item"
                    >
                      <router-link
                        :to="{ name: 'media-library' }"
                        @click="moreHover = false"
                      >
                        <div class="profile_picture d-flex align-items-center">
                          <div class="picture_block pt-1">
                            <i class="fas fa-images"></i>
                          </div>
                          <div class="text_block blog_link !cursor-pointer">
                            <p
                              class="text-md font-semibold text-gray-900 pt-1.5"
                            >
                              Media Library
                            </p>
                          </div>
                        </div>
                      </router-link>
                    </div>

                    <!-- Discover -->
                    <div class="d-item discover-dropdown">
                      <b-dropdown variant="outline-none" no-caret dropright>
                        <template v-slot:button-content>
                          <div
                            class="profile_picture d-flex align-items-center"
                          >
                            <div class="picture_block">
                              <i class="fas fa-compass"></i>
                            </div>
                            <div class="text_block blog_link">
                              <p class="text-md font-semibold text-gray-900"
                                >Discover <i class="ml-3 far fa-angle-right" />
                              </p>
                            </div>
                          </div>
                        </template>
                        <transition name="slide-bottom">
                          <div class="w-[300px]">
                            <div class="d-item">
                              <router-link
                                data-cy="header_content"
                                :to="{
                                  name: 'discover_topics',
                                  params: {
                                    workspace:
                                      getWorkspaces.activeWorkspace.slug,
                                  },
                                }"
                                @click="moreHover = false"
                              >
                                <div
                                  class="profile_picture d-flex align-items-start"
                                >
                                  <div class="picture_block">
                                    <i class="far fa-window-restore"></i>
                                  </div>
                                  <div class="text_block blog_link">
                                    <p class="text">Content Feed</p>
                                    <p class="sub_text"
                                      >Research the top perform content across
                                      your niche and share it with your
                                      audience.
                                    </p>
                                  </div>
                                </div>
                              </router-link>
                            </div>

                            <div
                                v-tooltip="!influencersAccess.allowed ? influencersAccess.error?.message : ''"
                                class="d-item"
                                :class="{ 'disabled-item': !influencersAccess.allowed }"
                            >
                              <a
                                  v-if="!influencersAccess.allowed"
                                  href="javascript:;"
                                  class="item"
                                  @click.prevent="showUpgradeModal"
                              >
                                <div class="profile_picture d-flex align-items-start">
                                  <div class="picture_block">
                                    <i class="fas fa-lock"></i>
                                  </div>
                                  <div class="text_block blog_link">
                                    <p class="text">Influencers</p>
                                    <p class="sub_text">
                                      Identify the key influencers and pioneers in your industry from social media channels.
                                    </p>
                                  </div>
                                </div>
                              </a>
                              <router-link
                                  v-else
                                  data-cy="header_influencers"
                                  :to="{
                                    name: 'instagram_influencers',
                                    params: { workspace: getWorkspaces.activeWorkspace.slug },
                                  }"
                                  @click.native="DiscoverHover = false"
                              >
                                <div class="profile_picture d-flex align-items-start">
                                  <div class="picture_block">
                                    <i class="far fa-users"></i>
                                  </div>
                                  <div class="text_block blog_link">
                                    <p class="text">Influencers</p>
                                    <p class="sub_text">
                                      Identify the key influencers and pioneers in your industry from social media channels.
                                    </p>
                                  </div>
                                </div>
                              </router-link>
                            </div>
                          </div>
                        </transition>
                      </b-dropdown>
                    </div>
                  </div>
                </transition>
              </li>
            </template>
          </ul>
          <ul class="icons">
            <li class="d-none">
              <div class="help_dropdown dropdown default_style_dropdown">
                <div class="dropdown_header" data-toggle="dropdown">
                  <a href="" class="question_icon">
                    <i class="d-block cs-question-mark"></i>
                  </a>
                </div>
                <div class="dropdown-menu dropdown-menu-right">
                  <ul class="inner">
                    <li class="list_item">
                      <a href="http://docs.contentstudio.io/" target="_blank">
                        Knowledgebase
                      </a>
                    </li>
                    <li class="list_item">
                      <a href="javascript:void(0)" onclick="Beacon('open')">
                        Help desk
                      </a>
                    </li>
                    <li class="list_item">
                      <a
                        href="https://trello.com/b/YZgQcEHz/contentstudio-product-roadmap"
                        target="_blank"
                      >
                        Public Roadmap
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>

            <li class="dropdown_li">
              <div
                class="head_notification_dropdown dropdown default_style_dropdown"
              >
                <div class="dropdown_header has-tooltip">
                  <a
                    href="javascript:void(0);"
                    class="bell_icon_light"
                    @click.prevent="notificationDropdown"
                  >
                    <div class="relative cursor-pointer">
                      <div
                        v-if="getWorkspaceNotifications.all_count > 0"
                        class="absolute -right-0.5 -top-3 text-white px-0.5 min-w-5 h-5 bg-red-500 rounded-full flex justify-center items-center overflow-hidden"
                      >
                        <span
                          class="text-xs whitespace-nowrap overflow-ellipsis"
                        >
                          {{
                            getWorkspaceNotifications?.all_count?.toLocaleString()
                          }}
                        </span>
                      </div>
                      <img
                        :class="{
                          vibrate_it: getWorkspaceNotifications.all_count > 0,
                        }"
                        src="@src/assets/img/top_navigation/icons/bell.svg"
                        alt="notification"
                        class="h-8"
                      />
                    </div>
                  </a>
                </div>
                <transition name="slide-bottom">
                  <TopNotificationDropdown
                    v-if="toggleNotificationDropdown"
                    v-click-away="notificationDropdownClose"
                  ></TopNotificationDropdown>
                </transition>
              </div>
            </li>
          </ul>
          <ul class="header-profile-menu">
            <li class="header-profile-menu__navigation">
              <div
                id="profile_dropdown_id"
                :class="{ limited_user: !hasPermission('can_use_actions') }"
                class="prevent_close_dropdown dropdown profile_dropdown default_style_dropdown header-profile-menu__navigation__dropdown"
              >
                <div
                  data-display="static"
                  class="dropdown_header header-profile-menu__navigation__dropdown__header"
                  data-toggle="dropdown"
                  data-cy="profile_dropdown_id"
                  @click="closeDropdownHeadway"
                >
                  <div
                    class="header-profile-menu__navigation__dropdown__profile_image"
                  >
                    <img
                      v-if="getProfile.image"
                      class="img"
                      :src="getProfile.image"
                      alt="profile image"
                      @error="
                        $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
                      "
                    />
                    <div
                      v-else
                      class="img"
                      style="
                        background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                      "
                    ></div>
                  </div>
                  <i class="cs-dots-v"></i>
                  <i class="cs-cross"></i>
                </div>
                <div class="dropdown-menu dropdown-menu-right">
                  <ul class="header-profile-menu__navigation__dropdown__inner">
                    <div
                      class="header-profile-menu__navigation__dropdown__inner__profile_details"
                    >
                      <div
                        class="header-profile-menu__navigation__dropdown__inner__profile_details__picture"
                      >
                        <div
                          class="header-profile-menu__navigation__dropdown__inner__profile_details__picture__block"
                        >
                          <img
                            v-if="getProfile.image"
                            class="img"
                            :src="getProfile.image"
                            alt="profile image"
                            @error="
                              $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
                            "
                          />
                          <div
                            v-else
                            class="img"
                            style="
                              background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                            "
                          ></div>
                        </div>
                        <div
                          class="header-profile-menu__navigation__dropdown__inner__profile_details__text_block"
                        >
                          <p class="text"
                            >{{ getProfile.firstname }}
                            <span>{{ getProfile.lastname }}</span>
                          </p>
                          <p class="sub_text">{{ getProfile.email }}</p>

                          <p
                            class="sub_text role_text"
                            :class="getUserRoleClass"
                          >
                            {{ getUserRole }}</p
                          >
                        </div>
                      </div>

                      <div
                        class="header-profile-menu__navigation__dropdown__inner__profile_details__logout_section"
                      >
                        <a
                          href="javascript:;"
                          data-cy="signout"
                          @click.prevent="handleLogout"
                        >
                          <i class="cs-logout"></i>
                          <span class="text align-middle">Sign Out</span>
                        </a>
                      </div>
                    </div>

                    <div
                      v-if="getWorkspaces?.activeWorkspace?.slug"
                      class="header-profile-menu__navigation__dropdown__inner__items"
                    >
                      <div
                        v-if="hasPermission('can_access_top_header')"
                        class="header-profile-menu__navigation__dropdown__inner__items__left_section"
                      >
                        <h4 class="heading">Workspace Settings</h4>

                        <li @click="profileRouter">
                          <router-link
                            data-cy="basic_setting"
                            :to="{
                              name: 'basicSetting',
                              params: {
                                workspace: getWorkspaces.activeWorkspace.slug,
                              },
                            }"
                          >
                            <i class="icon-settings-cs"></i>
                            <span class="text">Basic Settings</span>
                          </router-link>
                        </li>

                        <!-- team members-->
                        <li
                            v-if="hasPermission('can_view_team')"
                            @click="profileRouter"
                        >
                          <router-link
                              data-cy="team_members"
                              :to="{
                              name: 'team',
                              params: {
                                workspace: getWorkspaces.activeWorkspace.slug,
                              },
                            }"
                          >
                            <i class="icon-team-member-cs"></i>
                            <span class="text" data-cy="team-members">Team Members</span>
                          </router-link>
                        </li>

                        <!-- content categories -->
                        <li @click="profileRouter">
                          <router-link
                              data-cy="content_categories"
                              :to="{
                              name: 'content_categories',
                              params: {
                                workspace: getWorkspaces.activeWorkspace.slug,
                              },
                            }"
                          >
                            <i class="cs-social-account"></i>
                            <span class="text">Content Categories</span>
                          </router-link>
                        </li>
                        <li @click="profileRouter">
                          <router-link
                            data-cy="social_accounts"
                            :to="{
                              name: 'social',
                              params: {
                                workspace: getWorkspaces.activeWorkspace.slug,
                              },
                            }"
                          >
                            <i class="icon-social-accounts-cs"></i>
                            <span class="text">Social Accounts</span>
                            <img
                              src="@src/assets/img/common/new_tag.svg"
                              alt="new-tag"
                              class="ml-1"
                            />
                          </router-link>
                        </li>
                        <li @click="profileRouter">
                          <router-link
                            data-cy="blog_websites"
                            :to="{
                              name: 'blogs',
                              params: {
                                workspace: getWorkspaces.activeWorkspace.slug,
                              },
                            }"
                          >
                            <i class="icon-blogs-websites-cs"></i>
                            <span class="text">Blogs/Websites</span>
                          </router-link>
                        </li>

                        <li @click="profileRouter">
                          <router-link
                            data-cy="other_integrations"
                            :to="{
                              name: 'integrations',
                              params: {
                                workspace: getWorkspaces.activeWorkspace.slug,
                              },
                            }"
                          >
                            <i class="icon-other-integration-cs"></i>
                            <span class="text">Other Integrations</span>
                          </router-link>
                        </li>

                        <li
                          v-if="hasPermission('can_save_source')"
                          @click="profileRouter"
                        >
                          <router-link
                            data-cy="feeder_manage_sources"
                            :to="{
                              name: 'feeder_manage_sources',
                              params: {
                                workspace: getWorkspaces.activeWorkspace.slug,
                              },
                            }"
                          >
                            <i class="icon-sources-cs"></i>
                            <span class="text">Sources</span>
                          </router-link>
                        </li>

                        <li @click="profileRouter">
                          <router-link
                            data-cy="miscellaneous"
                            :to="{
                              name: 'miscellaneous',
                              params: {
                                workspace: getWorkspaces.activeWorkspace.slug,
                              },
                            }"
                          >
                            <i class="icon-miscellaneous-cs"></i>
                            <span class="text">Miscellaneous</span>
                          </router-link>
                        </li>
                        <li
                          v-if="isAnalyticsEnabledForUser"
                          @click="profileRouter"
                        >
                          <router-link
                            data-cy="reportsettings"
                            :to="{ name: 'reportsettings' }"
                          >
                            <i class="icon-reports_icon"></i>
                            <span class="text">Reports Settings</span>
                          </router-link>
                        </li>
                      </div>
                      <div
                        class="header-profile-menu__navigation__dropdown__inner__items__right_section"
                      >
                        <h4 class="heading">Account Settings</h4>

                        <li @click="profileRouter">
                          <router-link
                            data-cy="profile_setting"
                            :to="{
                              name: 'profile',
                              params: {
                                workspace: getWorkspaces.activeWorkspace.slug,
                              },
                            }"
                          >
                            <i class="icon-profile-cs"></i>
                            <span class="text">Your Profile</span>
                          </router-link>
                        </li>
                        <li
                          v-if="hasPermission('can_see_subscription')"
                          @click="profileRouter"
                        >
                          <router-link
                            data-cy="plan"
                            :to="{
                              name: 'plan',
                              params: {
                                workspace: getWorkspaces.activeWorkspace.slug,
                              },
                            }"
                          >
                            <i
                              :class="
                                checkIfActiveWorkspaceHasLimitExceeded()
                                  ? 'fas fa-exclamation-triangle limit_issue_billing'
                                  : 'icon-plan-cs'
                              "
                            ></i>
                            <span class="text">Billing &amp; Plan</span>
                          </router-link>
                        </li>
                        <li @click="profileRouter">
                          <router-link
                            data-cy="notifications"
                            :to="{
                              name: 'notifications',
                              params: {
                                workspace: getWorkspaces.activeWorkspace.slug,
                              },
                            }"
                          >
                            <i class="icon-notificaion-cs"></i>
                            <span class="text">Notifications</span>
                          </router-link>
                        </li>
                        <li
                          v-if="
                            getTeamMembership === 'team' &&
                            !shouldShowWhiteLabelData
                          "
                          class="position-relative"
                          @click="profileRouter"
                        >
                          <router-link
                            class=""
                            :to="{ name: 'emailNotificationStatus' }"
                          >
                            <i class="far fa-envelope"></i>
                            <span class="text">Email Status</span>
                            <div
                              class="line_animate"
                              :class="{
                                animate:
                                  $route.name === 'emailNotificationStatus',
                              }"
                            ></div>
                          </router-link>
                        </li>
                        <li
                          v-if="
                            (!shouldShowWhiteLabelData &&
                              getTeamMembership === 'team') ||
                            (shouldShowWhiteLabelData &&
                              getUserRole === 'Super Admin')
                          "
                          class="position-relative"
                          @click="profileRouter"
                        >
                          <router-link
                            :to="{ name: 'refer&earn' }"
                            class="refer-link"
                          >
                            <i class="fa fa-gift"></i>
                            <span class="text d-flex align-items-center">
                              Refer &amp; Earn
                            </span>
                          </router-link>
                        </li>
                        <li
                            v-if="hasPermission('white_label') && featureFlag()"
                            class="position-relative"
                            @click="profileRouter"
                        >
                          <template v-if="whiteLabelAddonAccess.allowed">
                            <router-link class="" :to="{ name: 'white-label' }">
                              <img src="@src/assets/img/settings/white-label/wl-icon.svg" alt="wl-icon" class="h-4 w-4">
                              <span class="text">White Label</span>
                              <div
                                  class="line_animate"
                                  :class="{
                                    animate: $route.name === 'white-label',
                                  }"
                              ></div>
                            </router-link>
                          </template>
                          <template v-else>
                            <a
                                v-tooltip.bottom="whiteLabelAddonAccess?.error?.message"
                                href="javascript:;"
                                class="cursor-default"
                                @click.prevent="showUpgradeModal"
                            >
                              <img src="@src/assets/img/settings/white-label/wl-icon.svg" alt="wl-icon" class="h-4 w-4">
                              <span class="text !text-gray-500">
                                White Label
                                <img
                                    src="@assets/img/icons/lock_black.svg"
                                    class="w-4 h-4 inline-block ml-2"
                                    alt="Locked"
                                />
                              </span>
                              <div class="line_animate"></div>
                            </a>
                          </template>
                        </li>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.top-navbar {
  background: linear-gradient(to bottom, #005fd0, #0068e5);
}

.min-w-5 {
  min-width: 1.25rem;
}

.min-w-6 {
  min-width: 1.5rem;
}
</style>

<style lang="less">
.limit_issue_billing {
  color: red !important;
  font-size: 15px !important;
}

.d-item a {
  all: initial !important;

  .sub_text {
    font-weight: 400;
    @apply pt-1;
  }
}

.new-feature-available {
  // right: 2.8rem !important;
  //   top: 0px;
  background: #ffaf2e !important;
  color: #fff !important;
  font-size: 0.7vw !important;
  line-height: 14px !important;
  margin-left: 0.5rem !important;

  &--sidebar {
    font-size: 0.7rem !important;
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}

.refer-link {
  display: inline-flex !important;
  white-space: nowrap;
}

.past_due_header {
  top: 0px !important;
}

.discover-dropdown {
  .b-dropdown {
    button.btn {
      padding: 0 !important;
      margin-left: 0 !important;
    }
    .dropdown-menu {
      border: none !important;
      border-radius: 8px !important;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1) !important;
    }
  }
}
</style>
