import { computed, reactive, ref, toRefs, watch } from 'vue'
import { useStore } from '@state/base'
import proxy from '@common/lib/http-common'
import { saveWhiteLabelGeneralSettings } from '@src/modules/setting/config/api-utils'
import useWhiteLabel from '@modules/setting/composables/whitelabel/useWhiteLabel'
import usePlatform from '@common/composables/usePlatform'
import useWhiteLabelApplication from '@modules/setting/composables/whitelabel/useWhiteLabelApplication'

const fields = reactive({
  businessName: { value: '', error: '', isRequired: true, tooltip: 'Enter the name of your business or brand as you want it to appear throughout the platform. This name will be visible to your clients and in notifications.' },
  websiteURL: { value: '', error: '', isRequired: true, tooltip: 'Enter your business website URL. This link will be used in emails to redirect clients to your site.' },
  brandLogo: { value: '', error: '', isRequired: true, tooltip: 'Upload your logo (250x150 px) to represent your brand across the platform and in emails.' },
  favicon: { value: '', error: '', isRequired: true, tooltip: 'Upload a 32x32 px icon for browser tabs to represent your brand on the platform.' },
  termsAndConditions: {
    value: 'https://contentstudio.io/terms-and-conditions',
    error: '',
    isRequired: false,
  },
  privacyPolicy: {
    value: 'https://contentstudio.io/privacy-policy',
    error: '',
    isRequired: false,
  },
})

const isLoading = ref(false)
const isApiError = ref(false)

export default function useGeneralSettings() {
  const { getters } = useStore()

  const { showToast } = usePlatform()
  const {
    isSetupComplete,
    isWhiteLabelEnabled,
    whiteLabelId,
    generalSettings,
    transformGeneralSettings,
    isWhiteLabelUnlocked
  } = useWhiteLabel()

  const { fetchWhiteLabelSettings } = useWhiteLabelApplication()

  const isFormValid = computed(() => {
    return Object.values(fields).every(
      (field) => !field.isRequired || (field.value && !field.error)
    )
  })

  const shouldDisableNext = computed(
    () => isLoading.value || !isFormValid.value || isApiError.value
  )

  watch(
    () => generalSettings.value,
    (newVal) => {
      setGeneralSettings(newVal)
    }
  )

  function setGeneralSettings(settings = {}) {
    if (settings) {
      // reset errors
      clearErrors()

      fields.businessName.value = settings.businessName || ''
      fields.websiteURL.value = settings.websiteURL || ''
      fields.brandLogo.value = settings.brandLogo || ''
      fields.favicon.value = settings.favicon || ''
    }
  }

  const setErrors = (errors = {}) => {
    if (errors) {
      if (errors.business_name)
        fields.businessName.error = errors.business_name[0]
      if (errors.website_url) fields.websiteURL.error = errors.website_url[0]
      if (errors.logo) fields.brandLogo.error = errors.logo[0]
      if (errors.favicon) fields.favicon.error = errors.favicon[0]
      if (errors.terms_of_service)
        fields.termsAndConditions.error = errors.terms_of_service[0]
      if (errors.privacy_policy)
        fields.privacyPolicy.error = errors.privacy_policy[0]
    }
  }

  const clearErrors = () => {
    Object.values(fields).forEach((field) => {
      field.error = ''
    })
  }

  const getPayload = () => {
    const formData = new FormData()

    if (fields.brandLogo.value && typeof fields.brandLogo.value !== 'string')
      formData.append('logo', fields.brandLogo.value)
    if (fields.favicon.value && typeof fields.favicon.value !== 'string')
      formData.append('favicon', fields.favicon.value)
    if (fields.websiteURL.value)
      formData.append('website_url', fields.websiteURL.value)

    if (whiteLabelId.value) formData.append('id', whiteLabelId.value)

    formData.append('workspace_id', getters.getActiveWorkspace._id)
    formData.append('business_name', fields.businessName.value)
    formData.append('terms_of_service', fields.termsAndConditions.value)
    formData.append('privacy_policy', fields.privacyPolicy.value)

    return formData
  }

  const handleSubmit = async () => {

    if(!isWhiteLabelUnlocked.value){
      return  showToast('White Label Addons not purchased', 'error')
    }

    clearErrors()

    isLoading.value = true

    const payload = getPayload()

    try {
      const { data } = await proxy.post(saveWhiteLabelGeneralSettings, payload)
      if (data.status) {
        isApiError.value = !data.status
        whiteLabelId.value = data.whitelabel._id
        const formattedData = transformGeneralSettings(data.whitelabel)
        setGeneralSettings(formattedData)
        await showToast('General settings saved successfully', 'success')
        if (isSetupComplete.value && isWhiteLabelEnabled.value) {
          fetchWhiteLabelSettings()
        }
      } else {
        await showToast(data.message, 'warn')
      }
      return data.status
    } catch (error) {
      isApiError.value = true
      await showToast(error.response?.data?.message, 'error')

      const errors = error.response?.data?.errors || []
      setErrors(errors)

      return false
    } finally {
      isLoading.value = false
    }
  }

  return {
    ...toRefs(fields),

    isLoading,
    shouldDisableNext,

    handleSubmit,
  }
}
