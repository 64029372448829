import { computed, reactive, ref, toRefs, watch } from 'vue'
import { useStore } from '@state/base'
import proxy from '@common/lib/http-common'
import {
  findHostFromDomain,
  saveWhiteLabelDomainSettings,
  validateWhiteLabelDomainSettings,
} from '@src/modules/setting/config/api-utils'
import useWhiteLabel from '@modules/setting/composables/whitelabel/useWhiteLabel'
import usePlatform from '@common/composables/usePlatform'

const fields = reactive({
  whiteLabelDomain: {
    value: '',
    error: '',
    isRequired: true,
    tooltip:
      'Enter the custom domain (e.g., yourbrand.com) where clients will access the platform, reinforcing your brand identity.',
  },
  dnsType: {
    value: 'CNAME',
    error: '',
    isRequired: false,
    tooltip:
      ' This field defines the DNS record type, such as CNAME based on your configuration needs. This information is usually provided by the service you are setting up (e.g., email provider, hosting service, or platform provider).',
  },
  domainHost: {
    value: '@',
    error: '',
    isRequired: false,
    tooltip:
      'This field specifies the subdomain or hostname within your domain that the DNS record applies to.The host (subdomain or root domain) details are also provided by the service provider.',
  },
  domainValue: {
    value:
      process.env.VUE_APP_WHITELABEL_CNAME_VALUE ||
      'whitelabel.contentstudio.com',
    error: '',
    isRequired: false,
    tooltip:
      'This field holds the actual data associated with the DNS record. The value (e.g., IP address, domain name, or text string) is provided by the service requiring the DNS setup.',
  },
})

const isLoading = ref(false)
const isDomainVerified = ref(false)
const isApiError = ref(false)

export default function useDomainSettings() {
  const { getters } = useStore()

  const { showToast } = usePlatform()
  const { whiteLabelId, domainSettings, transformDomainSettings } =
    useWhiteLabel()

  const isFormValid = computed(() => {
    return Object.values(fields).every(
      (field) => !field.isRequired || (field.value && !field.error)
    )
  })

  const shouldDisableNext = computed(
    () => isLoading.value || !isFormValid.value || isApiError.value
  )

  watch(
    () => domainSettings.value,
    (newVal) => {
      setDomainSettings(newVal)
    }
  )

  function setDomainSettings(settings = {}) {
    if (settings) {
      // reset errors
      clearErrors()

      fields.whiteLabelDomain.value = settings.whiteLabelDomain || ''
    }
  }

  const setErrors = async (error = {}) => {
    await showToast(`${error.response?.data?.message}`, 'error')

    const errors = error.response?.data?.errors || []

    if (errors.domain) fields.whiteLabelDomain.error = errors.domain[0]
  }

  const clearErrors = () => {
    Object.values(fields).forEach((field) => {
      field.error = ''
    })
  }

  const getDomain = (urlOrDomain) => {
    try {
      // If it's a full URL, the URL constructor will parse it correctly
      const url = new URL(
        urlOrDomain.includes('http') ? urlOrDomain : `https://${urlOrDomain}`
      )
      return url.hostname
    } catch (error) {
      // Fallback in case of invalid input
      return urlOrDomain
    }
  }

  const getPayload = () => {
    const payload = {
      domain: getDomain(fields.whiteLabelDomain.value),
      workspace_id: getters.getActiveWorkspace._id,
    }

    if (whiteLabelId.value) {
      payload.id = whiteLabelId.value
    }

    return payload
  }

  const validateDomain = async () => {
    clearErrors()

    if (!fields.whiteLabelDomain.value) {
      fields.whiteLabelDomain.error = 'Domain is required'
      return
    }

    isLoading.value = true

    const payload = getPayload()

    try {
      const { data } = await proxy.post(
        validateWhiteLabelDomainSettings,
        payload
      )
      const message = data.status
        ? `Domain settings saved successfully`
        : data.message
      const type = data.status ? 'success' : 'warn'

      await showToast(message, type)

      isDomainVerified.value = data.status
      isApiError.value = !data.status
      return data.status
    } catch (error) {
      isDomainVerified.value = false
      isApiError.value = true
      await setErrors(error)
      return false
    } finally {
      isLoading.value = false
    }
  }

  const handleSubmit = async () => {
    clearErrors()

    isLoading.value = true

    const payload = getPayload()

    try {
      const { data } = await proxy.post(saveWhiteLabelDomainSettings, payload)
      if (!data.status) {
        await showToast(data.message, 'warn')
      } else {
        isApiError.value = false
        const formattedData = transformDomainSettings(data.whitelabel)
        setDomainSettings(formattedData)
      }
      return data.status
    } catch (error) {
      isApiError.value = true
      await setErrors(error)

      return false
    } finally {
      isLoading.value = false
    }
  }

  const fetchHostFromDomain = async () => {
    try {
      const payload = getPayload()

      const { data } = await proxy.get(findHostFromDomain, {
        params: {
          ...payload,
        },
      })
      /// if data?.status is true then assign data?.data to domainHost
      if (data?.status) {
        fields.domainHost.value = data?.data
      }
    } catch (error) {
      await setErrors(error)
    }
  }

  return {
    ...toRefs(fields),
    isLoading,
    isDomainVerified,
    shouldDisableNext,

    validateDomain,
    handleSubmit,
    fetchHostFromDomain,
  }
}
