import { onMounted, ref } from 'vue'
import { getPaddleInstance, initializePaddle } from '@paddle/paddle-js'
import { useStore } from '@state/base'

export function usePaddle() {
  const { getters } = useStore()
  const classicInitialized = ref(false)
  const v1Initialized = ref(false)

  const priceIds = {
    standard: {
      monthly: 'pri_01jasghfpwf6t6qsrvsgr8v96c',
      annually: 'pri_01jasgk83hfpbqqp5ay34hmym4',
    },
    advanced: {
      monthly: 'pri_01jasgrxa26wk5xcnxt440gvt7',
      annually: 'pri_01jasgt4dqqvs5n70jkr8nc1cj',
    },
    'agency-unlimited': {
      monthly: 'pri_01jasgq8a85a7jwwbg6fx0tbqt',
      annually: 'pri_01jasgr75gyvqnp52jfqkczqyd',
    },
    socialAccounts: {
      monthly: {
        tier1: 'pri_01jc5sk5knzz4z7njjrsmcdzkr', // $1
        tier2: 'pri_01jc5sjjx59dng9xxmhd5e1b6q', // $2
        tier3: 'pri_01jc5shkyenkj3jrama1qw6kwg', // $3
        tier4: 'pri_01jc5sgzwve893d7a1e22tyv8b', // $4
        tier5: 'pri_01jbbyyqjy1skwpy1zcm63pvtg', // $5
      },
      annually: {
        tier1: 'pri_01jc5sqdx56f4j6aq72cxx1zdd', // $1
        tier2: 'pri_01jc5spptneteavsgasf0wmmeq', // $2
        tier3: 'pri_01jc5snw6fvqh40q1mn5qq6zrt', // $3
        tier4: 'pri_01jc5smqm9evkzptf95cm957d4', // $4
        tier5: 'pri_01jbef1fne251wfrh5d2km3zbd', // $5
      },
    },
  }

  // Initialize Paddle instance
  onMounted(async () => {
    try {
      console.log('Initializing both Paddle versions...')

      // Initialize both versions simultaneously
      await Promise.all([
        // Classic initialization
        initializePaddle({
          version: 'classic',
          environment: process.env.VUE_APP_ENVIRONMENT === 'production' ? 'production' : 'sandbox',
          vendor: parseInt(process.env.VUE_APP_PADDLE_VENDOR_ID),
          eventCallback: (event) => console.log('Classic event:', event),
          enableConcurrentVersions: true,
          debug: true,
          completeDetails: true,
        }).then(() => {
          console.log('Classic initialized successfully')
          classicInitialized.value = true
        }),

        // V1 initialization
        initializePaddle({
          version: 'v1',
          environment: process.env.VUE_APP_ENVIRONMENT === 'production' ? 'production' : 'sandbox',
          token: process.env.VUE_APP_PADDLE_BILLING_CLIENT_ID,
          eventCallback: (event) => {
            if (event?.name === 'checkout.completed') {
              setTimeout(() => {
                // reload the page to update the limits
                window.location.reload()
              }, 3000)
            }
          },
          enableConcurrentVersions: true,
          debug: true,
        }).then(() => {
          console.log('V1 initialized successfully')
          v1Initialized.value = true
        }),
      ])

      console.log('Both Paddle versions initialized')
    } catch (error) {
      console.error('Failed to initialize Paddle:', error)
    }
  })

  // Function to open the Paddle checkout
  const openCheckout = async (payload, forceVersion = null) => {
    const currentPlan = getters.getPlan?.subscription?.slug
    // Use forced version or determine based on plan
    const paddleVersion =
      forceVersion ||
      (getters.getPlan?.subscription?.paddle_billing ? 'v1' : 'classic')

    console.log('Opening checkout with:', {
      paddleVersion,
      currentPlan,
      isPaddleV2Plan: getters.getPlan?.subscription?.paddle_billing,
      payload,
    })

    // Check if the required version is initialized
    if (paddleVersion === 'classic' && !classicInitialized.value) {
      throw new Error('Paddle Classic is not initialized yet')
    }
    if (paddleVersion === 'v1' && !v1Initialized.value) {
      throw new Error('Paddle V1 is not initialized yet')
    }

    try {
      const paddleInstance = getPaddleInstance(paddleVersion)

      if (!paddleInstance) {
        console.error(`Failed to get ${paddleVersion} instance`)
      }

      payload.settings = {
        displayMode: 'inline',
        variant: 'one-page',
      };
      await paddleInstance.Checkout.open(payload);
    } catch (error) {
      console.error(`Failed to open ${paddleVersion} checkout:`, error)
      throw error
    }
  }

  return {
    priceIds,
    openCheckout,
  }
}