import {
  accountBaseUrl,
  automationBaseUrl,
  billingBaseUrl,
  discoveryBaseUrl,
  settingsBaseUrl,
} from '@src/config/api-utils'

export const fetchWorkspacesUrl = settingsBaseUrl + 'fetchWorkspaces'
export const fetchUserWorkspacesUrl = settingsBaseUrl + 'fetchUserWorkspaces'
export const fetchOnboardingWorkspacesUrl =
  settingsBaseUrl + 'fetchOnboardingWorkspaces'
export const uploadWorkspaceLogoUrl = settingsBaseUrl + 'uploadWorkspaceLogo'
export const fetchWorkspaceLogoSuggessionsUrl =
  settingsBaseUrl + 'fetchWorkspaceLogoSuggessions'
export const slugAvailableWorkspaceURL = settingsBaseUrl + 'slugAvailable'
export const saveWorkspaceUrl = settingsBaseUrl + 'saveWorkspace'
export const updateWorkspaceTimezone =
  settingsBaseUrl + 'updateWorkspaceTimezone'
export const removeWorkspaceUrl = settingsBaseUrl + 'removeWorkspace'
export const pausePostingURL = settingsBaseUrl + 'pausePosting'
export const resumePostingURL = settingsBaseUrl + 'resumePosting'
export const fetchPausePostingCountURL =
  settingsBaseUrl + 'fetchPausePostingCount'
export const fetchWorkspaceUrl = settingsBaseUrl + 'fetchWorkspace'
export const changeDefaultWorkspaceUrl =
  settingsBaseUrl + 'changeDefaultWorkspace'
export const manageAllMembersURL = settingsBaseUrl + 'manage/members'
export const bulkAddTeamMemberURL = settingsBaseUrl + 'team/bulk_add'
export const validateJoinTokenURL = settingsBaseUrl + 'team/validate_token'
export const joinOrganizationAndSetPasswordURL =
  settingsBaseUrl + 'team/join_and_set_password'
export const changeOrganizationTeamMemberStatusURL =
  settingsBaseUrl + 'team/change_team_member_role'
export const removeOrganizationTeamMemberAccessURL =
  settingsBaseUrl + 'team/remove_team_member_access'
export const removeOrganizationTeamMemberFromAllWorkspacesURL =
  settingsBaseUrl + 'team/remove_team_member_from_all_workspaces'
// HashTags
export const saveHashTagUrl = settingsBaseUrl + 'hashtag/save'
export const fetchHashTagsUrl = settingsBaseUrl + 'hashtag/fetch'
export const removeHashTagUrl = settingsBaseUrl + 'hashtag/remove'

// UTMs
export const saveUTMUrl = settingsBaseUrl + 'utm/save'
export const fetchUTMUrl = settingsBaseUrl + 'utm/fetch'
export const removeUtmUrl = settingsBaseUrl + 'utm/remove'
export const editUtmStatusUrl = settingsBaseUrl + 'utm/editStatus'
export const changeAttachUtmStatusUrl =
  settingsBaseUrl + 'utm/changeAttachUtmStatus'

// team member work
export const getUsersByEmailURL = settingsBaseUrl + 'getUsersByEmail'
export const addTeamMemberURL = settingsBaseUrl + 'addTeamMember'

export const resendInvitationURL = settingsBaseUrl + 'resendInvitation'
export const getMemberURL = settingsBaseUrl + 'getMember'
export const updateTeamMemberURL = settingsBaseUrl + 'updateTeamMember'
export const removeMemberURL = settingsBaseUrl + 'removeMember'
export const joinTeamURL = settingsBaseUrl + 'joinTeam'

// notifications
export const fetchNotificationsUrl =
  settingsBaseUrl + 'notifications/preference/get'
export const saveNotificationsUrl =
  settingsBaseUrl + 'notifications/preference/save'
export const fetchWorkspaceNotificationsUrl =
  settingsBaseUrl + 'notifications/workspace/get'
export const markNotificationRead =
  settingsBaseUrl + 'notifications/workspace/markRead'
export const fetchImportantNotificationsUrl =
  settingsBaseUrl + 'notifications/workspace/expire/accounts'

// onbaording
export const startOnboardingStepsURL = settingsBaseUrl + 'startOnboardingSteps'
export const fetchOnboardingTeamUrl = settingsBaseUrl + 'fetchOnboardingTeam'

export const onboardingStepsURL = settingsBaseUrl + 'onboarding/steps'
export const onboardingCompleteURL = settingsBaseUrl + 'onboarding/status'
export const onboardingWidgetURL = settingsBaseUrl + 'onboarding/widget/never'

// plan and subscription
export const planURL = billingBaseUrl + 'plan'
export const upgradePlanURL = billingBaseUrl + 'upgradeSubscription'
export const cancelSubscriptionURL = billingBaseUrl + 'cancelSubscription'
export const submitSupportRequestURL = billingBaseUrl + 'submitSupportRequest'
export const reopenAccountURL = billingBaseUrl + 'reopenAccount'
export const removeAccountDataURL = billingBaseUrl + 'removeAccountData'
export const checkoutURL = billingBaseUrl + 'fastspring/generateKey'
export const overridePriceURL = billingBaseUrl + 'paddle/overridePrice'

export const increaseLimitsURL = billingBaseUrl + 'paddle/increaseLimits'
export const checkPurchasedAddonsURL =
  billingBaseUrl + 'paddle/has_purchased_addons'
export const validateAndUpgradeToStarterPlan =
  billingBaseUrl + 'paddle/validate/upgrade_to_starter_plan'
export const whiteLabelAddonURL = billingBaseUrl + 'billing/subscription/whitelabel'

// sources

export const searchKeywordsBySourceURL = discoveryBaseUrl + 'sources/search'
export const feedSourcePreviewURL = discoveryBaseUrl + 'sources/preview'
export const addSourcesURL = discoveryBaseUrl + 'sources/add'
export const addSourcesFileURL = discoveryBaseUrl + 'sources/add_opml'
export const changeSourceStatusURL = discoveryBaseUrl + 'sources/change_status'
export const removeSourceURL = discoveryBaseUrl + 'sources/remove'
export const addSourceFromSuggestionURL =
  discoveryBaseUrl + 'sources/add_suggestion'
export const checkBlogURL = discoveryBaseUrl + 'sources/check'
export const addedSourcesURL = discoveryBaseUrl + 'sources/added'
export const updateSourcesCustomTopicsURL =
  discoveryBaseUrl + 'sources/update_custom_topics'

// blocked sources

export const addBlockSourceURL = discoveryBaseUrl + 'blocked_sources/add'
export const allBlockSourcesURL = discoveryBaseUrl + 'blocked_sources/all'
export const OPMLHistoryURL = discoveryBaseUrl + 'sources/import/history'
export const changeBlockedSourceStatusURL =
  discoveryBaseUrl + 'blocked_sources/change_status'
export const removeBlockedSourceURL =
  discoveryBaseUrl + 'blocked_sources/remove'

// bulk csv
export const processCsvURL = automationBaseUrl + 'processCsv'
export const fetchCsvPostsURL = automationBaseUrl + 'fetchCsvPosts'
export const csvPostActionURL = automationBaseUrl + 'csvPostAction'
export const csvPostBulkActionURL = automationBaseUrl + 'csvPostBulkAction'
export const csvCheckProcessURL = automationBaseUrl + 'csvCheckProcess'

export const developersAPIURL = accountBaseUrl + 'developers/api'

// content categories

export const storeCategoryURL = settingsBaseUrl + 'categories/store'
export const storeGlobalCategoryURL =
  settingsBaseUrl + 'global/categories/store'
export const storeGlobalCategorySlotsURL =
  settingsBaseUrl + 'global/categories/slots/store'
export const fetchCategoriesURL = settingsBaseUrl + 'categories/show'
export const deleteCategoryURL = settingsBaseUrl + 'categories/delete'
export const deleteGlobalCategoryURL =
  settingsBaseUrl + 'global/categories/delete'
export const shuffleCategoryPostsURL =
  settingsBaseUrl + 'categories/shuffle_posts'
export const deleteSlotCategoryURL = settingsBaseUrl + 'categories/slots/delete'
export const deleteAllCategorySlotsURL =
  settingsBaseUrl + 'categories/slots/delete_all'
export const nextAvailableSlotCategoryURL =
  settingsBaseUrl + 'categories/slots/next_slot'
export const storeCategorySlotURL = settingsBaseUrl + 'categories/slots/store'
export const updateCategorySlotURL = settingsBaseUrl + 'categories/slots/update'
export const fetchCategoriesSlotsURL = settingsBaseUrl + 'categories/slots/show'
export const storeTriggerURL = settingsBaseUrl + 'triggers/store'

// youtube Api key
export const fetchYoutubeApikeyURL = settingsBaseUrl + 'fetchYoutubeApikey'
export const updateYoutubeApikeyURL = settingsBaseUrl + 'updateYoutubeApikey'
export const removeYoutubeApikeyURL = settingsBaseUrl + 'removeYoutubeApikey'

// Email Notification Status
export const fetchEmailStatusURL = settingsBaseUrl + 'emailNotificationStatus'
export const fetchSingleEmailStatusURL =
  settingsBaseUrl + 'emailSingleNotificationStatus'
export const resendEmailURL = settingsBaseUrl + 'resendEmail'
export const previewEmailURL = settingsBaseUrl + 'previewEmail'

export const setWorkspacePreferencesUrl = settingsBaseUrl + 'setPreferences'
export const setUsermavenAnalyticsUrl = settingsBaseUrl + 'addUsermavenIframe'

// white label
export const fetchWhiteLabelWorkspaceData = settingsBaseUrl + 'whitelabel/get'
export const fetchWhiteLabelDomainData =
  settingsBaseUrl + 'whitelabel/getDomainDetails'

export const updateWhiteLabelStatus =
  settingsBaseUrl + 'whitelabel/updateStatus'

export const sendEmailToUsers =
  settingsBaseUrl + 'whitelabel/sendActivationEmail'

export const saveWhiteLabelGeneralSettings =
  settingsBaseUrl + 'whitelabel/generalSettings'

export const saveWhiteLabelDomainSettings =
  settingsBaseUrl + 'whitelabel/domainSettings'

export const saveWhiteLabelEmailSettings =
  settingsBaseUrl + 'whitelabel/emailSettings'

export const validateWhiteLabelSMTPSettings =
  settingsBaseUrl + 'whitelabel/validateSMTP'

export const validateWhiteLabelDomainSettings =
  settingsBaseUrl + 'whitelabel/validateDomain'

export const findHostFromDomain =
  settingsBaseUrl + 'whitelabel/findHostFromDomain'
