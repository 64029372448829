<script setup>
import {
  computed,
  defineAsyncComponent,
  onBeforeUnmount,
  onMounted,
  ref,
} from 'vue'
import useWhiteLabel from '@modules/setting/composables/whitelabel/useWhiteLabel'
import usePermissions from '@common/composables/usePermissions'
import LayoutCard from '@modules/setting/components/white-label/LayoutCard.vue'
import MaterialGuide from '@modules/setting/components/white-label/MaterialGuide.vue'
import { useStore } from '@state/base'
import { useRouter } from '@src/router'
import useDomainSettings from '@modules/setting/composables/whitelabel/useDomainSettings'
import useGeneralSettings from '@modules/setting/composables/whitelabel/useGeneralSettings'
import useEmailSettings from '@modules/setting/composables/whitelabel/useEmailSettings'
import SettingsIcon from '@assets/img/settings/white-label/settings.svg'
import EmailIcon from '@assets/img/settings/white-label/email.svg'
import DomainIcon from '@assets/img/settings/white-label/domain.svg'
import CompletionPopup from '@modules/setting/components/white-label/CompletionPopup.vue'
import WhiteLabelUpgradeModal from '@modules/setting/components/white-label/WhiteLabelUpgradeModal'
import {useFeatures} from "@modules/billing/composables/useFeatures";

const GeneralSettings = defineAsyncComponent(() =>
  import('@modules/setting/components/white-label/GeneralSettings.vue')
)
const DomainSettings = defineAsyncComponent(() =>
  import('@modules/setting/components/white-label/DomainSettings.vue')
)
const EmailSettings = defineAsyncComponent(() =>
  import('@modules/setting/components/white-label/EmailSettings.vue')
)

const { getters } = useStore()
const router = useRouter()

const {
  currentStep,
  isSetupComplete,
  isWhiteLabelUnlocked,
  isEditing,
  goToStep,
  nextStep,
  prevStep,
  fetchWhiteLabel,
  resetLocalStates,
} = useWhiteLabel()

const {canAccess} = useFeatures()
const whiteLabelAddonAccess = canAccess('white_label_addon')

const {
  shouldDisableNext: disableGeneralSettingsNavigation,
  handleSubmit: saveGeneralSettings,
} = useGeneralSettings()
const {
  shouldDisableNext: disableDomainSettingsNavigation,
  handleSubmit: saveDomainSettings,
} = useDomainSettings()
const {
  shouldDisableNext: disableEmailSettingsNavigation,
  handleSubmit: saveEmailSettings,
} = useEmailSettings()

const { hasPermission } = usePermissions()

const completedSteps = ref([false, false, false])
const whiteLabelLoader = ref(false)

const steps = [
  { name: 'General Settings', component: GeneralSettings },
  { name: 'Domain Settings', component: DomainSettings },
  { name: 'Email Settings', component: EmailSettings },
]

// classes for the steps and icons
const stepColors = ['!text-[#7272FF]', 'text-[#4CCE88]', 'text-[#FF9472]']

const stepsBorderColor = [
  '!border-[#7272FF]',
  '!border-[#4CCE88]',
  '!border-[#FF9472]',
]

const stepIcons = [SettingsIcon, DomainIcon, EmailIcon]

const disabledSteps = computed(() => {
  if (disableGeneralSettingsNavigation.value) {
    return [false, true, true]
  } else if (disableDomainSettingsNavigation.value) {
    return [false, false, true]
  } else if (disableEmailSettingsNavigation.value) {
    return [false, false, false]
  } else {
    return [false, false, false]
  }
})

onMounted(async () => {
  if (!hasPermission('white_label') || !whiteLabelAddonAccess.value?.allowed) {
    await router.push({
      name: 'dashboard',
      params: { workspace: getters.getActiveWorkspace.slug },
    })
    return
  }
  whiteLabelLoader.value = true
  await fetchWhiteLabel().then(() => {
    whiteLabelLoader.value = false
  })
})

onBeforeUnmount(() => {
  resetLocalStates()
  isEditing.value = false
})


const handleStepClick = async (index) => {
  if (!disabledSteps.value[index]) {
    if (index > currentStep.value) {
      // Save current step data when moving to a next step
      if (currentStep.value === 0) {
        await saveGeneralSettings()
        completedSteps.value[0] = true
      } else if (currentStep.value === 1) {
        await saveDomainSettings()
        completedSteps.value[1] = true
      } else if (currentStep.value === 2) {
        await saveEmailSettings()
        completedSteps.value[2] = true
      }
    }
    goToStep(index)
  }
}

const handleNextStep = () => {
  const nextStepIndex = currentStep.value + 1
  if (nextStepIndex < steps.length && !disabledSteps.value[nextStepIndex]) {
    completedSteps.value[currentStep.value] = true
    nextStep()
  }
}
</script>

<template>
  <template v-if="whiteLabelLoader">
    <div class="flex justify-center items-center h-[calc(100vh-60px)]">
      <clip-loader :color="'#436aff'" :size="'2rem'" />
    </div>
  </template>
  <div v-else class="relative overflow-x-hidden pr-12">
    <template v-if="!isWhiteLabelUnlocked">
      <div
        class="z-100 fixed top-[50%] left-[51%] right-[-11%] 2xl:left-[50%] 2xl:right-auto"
        style="transform: translate(-35%, -45%)"
      >
        <WhiteLabelUpgradeModal />
      </div>
      <div
        class="bg-[#595c5f40] pointers-events-none z-50 h-full w-full absolute"
      ></div>
    </template>

    <template v-if="isSetupComplete && !isEditing">
      <div
          class="z-100 fixed top-[50%] left-[51%] right-[-11%] 2xl:left-[50%] 2xl:right-auto"
          style="transform: translate(-35%, -45%)"
      >
        <CompletionPopup />

      </div>
      <div
          class="bg-[#595c5f40] pointers-events-none z-50 h-full w-full absolute"
      ></div>
    </template>

    <div class="h-[calc(100vh-60px)] max-w-4xl mx-auto pt-8">
      <div class="grid grid-cols-6 xl:grid-cols-3 gap-4 pb-8">
        <LayoutCard
          custom-classes="col-span-6 xl:col-span-3 rounded-2xl bg-white py-5"
        >
          <template v-slot:body>
            <p class="text-xl font-bold mb-2 text-center text-[#3A4557]"
              >White Label Setup</p
            >
            <p class="text-center text-[#595C5F] text-md-center"
              >Adjust your custom branding according to your brand</p
            >
            <div
              class="flex gap-4 items-center justify-center mb-3 pt-5 w-full"
            >
              <template v-for="(step, index) in steps" :key="index">
                <div
                  :class="[
                    disabledSteps[index]
                      ? 'cursor-not-allowed opacity-50'
                      : 'cursor-pointer',
                  ]"
                  class="flex items-center"
                  @click="handleStepClick(index)"
                >
                  <div
                    :class="[
                      'w-11 h-11 min-w-11 min-h-11 rounded-full flex items-center justify-center border text-xl',
                      currentStep === index
                        ? stepsBorderColor[index]
                        : '!border-gray-300',
                    ]"
                  >
                    <template
                      v-if="completedSteps[index] && currentStep !== index"
                    >
                      <svg
                        :class="stepColors[index]"
                        class="h-6 w-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 13l4 4L19 7"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                      </svg>
                    </template>
                    <img
                      v-else
                      :src="stepIcons[index]"
                      alt=""
                      class="w-5 h-5"
                    />
                  </div>
                  <span
                    :class="[
                      currentStep === index
                        ? `font-medium ${stepColors[index]}`
                        : '',
                    ]"
                    class="ml-2"
                  >
                    {{ step.name }}
                  </span>
                </div>
                <div
                  v-if="index < steps.length - 1"
                  class="w-16 h-px border !border-dashed"
                ></div>
              </template>
            </div>
          </template>
        </LayoutCard>

        <LayoutCard
          custom-classes="col-span-6 xl:col-span-3 rounded-2xl bg-white py-5"
        >
          <template v-slot:header>
            <div class="flex justify-between items-center">
              <p class="font-medium text-lg">{{ steps[currentStep].name }}</p>
            </div>
          </template>
          <template v-slot:body>
            <component
              :is="steps[currentStep].component"
              @next="handleNextStep"
              @prev="prevStep"
            />
            <MaterialGuide class="rounded-2xl" />
          </template>
        </LayoutCard>
      </div>

    </div>
  </div>
</template>
